.header {
    position: absolute;
    left: 0;
    top: 0;
    padding: 30px 0;
    width: 100%;

    &__container {
        @include flex-all-sb;

        @include high-tablet {
          justify-content:  initial;
          align-items: center;
        }
    }

    &__phone {
      @include high-tablet {
        border:1px solid rgba(255, 255, 255, 0.08);
        border-radius: 10px;
        margin-left: auto;
        margin-right: 10px;
        width: 44px;
        height: 44px;
        font-size: 0;
        background-image: url('../img/phone.svg');
        background-size: 24px 24px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
}
