.footer {
  background-color: var(--dark-color);
  padding: 40px 0;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include mobile {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__logo {
    display: inline-block;
    margin-bottom: 5px;
  }

  &__copyright {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: rgba(255, 255, 255, 0.7);

    @include mobile {
      margin-bottom: 30px;
      text-align: center;
    }
  }
}
