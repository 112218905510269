.social {
  display: flex;
  align-items: center;

  &__item {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__link {
    display: block;
    width: 30px;
    height: 30px;

    svg {
      display: block;
      width: 100%;
      height: 100%;
      fill: var(--dark-color);
      object-fit: cover;
    }
  }
}
