// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Jost", sans-serif;
  --content-width: 1160px;
  --content-fluid-width: 1790px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(var(--content-fluid-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
  --accent-color: #d52027;
  --dark-color: #141024;
  --text-color: rgba(20, 16, 36, .7);
}
