.tooltip {
  margin-left: 10px;
  &__btn {
    @include flex-all-center;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    background: rgba(20, 16, 36, 0.08);

    svg {
      width: 4px;
      height: 14px;
    }

    &:focus {
      + .tooltip__txt {
        opacity: 1;
        visibility: visible;
      }
    }

    @include hover {
      + .tooltip__txt {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__txt {
    border-radius: 5px;
    padding: 15px 20px;
    box-shadow: 0 2px 8px rgba(20, 16, 36, 0.1);

    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: var(--dark-color);
    background-color: var(--light-color);
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;

    @include hover {
        opacity: 1;
        visibility: visible;
    }
  }
}
