.trainers {
  &__wrapper {
    padding-left: max(15px, calc((100% - var(--content-width)) / 2));
  }

  &__content {
    margin-bottom: 75px;
  }

  .swiper-slide {
    width: 360px;

    @include mobile {
      width: 220px;
    }

    &::last-child {
      margin-right: var(--container-offset);
    }
  }

  .swiper-scrollbar-drag {
    background-color: var(--dark-color);
  }

  &__name {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 24px;
    line-height: 160%;
    color: var(--dark-color);
  }

  &__post {
    margin: 0;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: var(--text-color);
  }

  &__btn {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: var(--accent-color);
  }

  &__image {
    border-radius: 20px;
    margin-bottom: 20px;
    max-height: 500px;

    @include mobile {
      max-height: 306px;
    }
  }

  &__nav {
    @include flex-all-sb
  }

  &__scroll {
    border-radius: 40px;
    width: 360px;
    height: 5px;
    background: rgba(20, 16, 36, 0.08);
  }

  &__btns {
    @include flex-v-center;

    @include mobile {
      display: none;
    }
  }

  &__slider-btn {
    @include bg-cover;

    width: 40px;
    height: 40px;

    &--prev {
      background-image: url('../img/arrow-left.svg');
    }
    &--next {
      background-image: url('../img/arrow-right.svg');
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

