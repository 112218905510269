.graph-modal__close {
  right: 40px;
  top: 40px;
  width: auto;
  height: auto;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: var(--text-color);

  @include mobile {
    top: 20px;
    right: 20px;
  }
}

.graph-modal {
  &::before {
    @include tablet {
      display: none;
    }
  }
}

.popup {
  max-width: 940px;
  width: 100%;
  padding: 40px;

  &__top {
    display: flex;
    margin-bottom: 40px;

    @include mobile {
      flex-direction: column;
    }
  }

  &__bottom-content {
    padding-top: 20px;
    padding-right: 20px;
    max-height: 375px;
    overflow-y: auto;
  }

  &__image {
    margin-right: 40px;

  }

  &__name {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 24px;
    line-height: 160%;
    color: var(--dark-color);
  }

  &__post {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: var(--text-color);
  }

  &__descr {
    margin-bottom: 40px;
  }

  &__text {
    margin: 0;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: var(--dark-color);
  }

  &__subtitle {
    margin: 0;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 160%;
    color: var(--dark-color);
  }

  .simplebar-track.simplebar-vertical {
    border-radius: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 5px;
    background-color: rgba(20, 16, 36, 0.08);
  }

  .simplebar-scrollbar:before {
    left: 0;
    right: 0;
    border-radius: 40px;
    width: 5px;
    background-color: var(--dark-color);
    opacity: 1;
  }

  @include small-tablet {
    padding-top: 80px;
  }

  @include mobile {
    padding: 20px;
    padding-top: 40px;
  }
}

.tabs {

  &__menu {
    border-bottom: 1px solid rgba(20, 16, 36, 0.11);
    padding-bottom: 20px;
  }
  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 70%;

    &-item {
      @include mobile {
        margin-right: 40px;
        margin-bottom: 20px;
      }
    }
    &-btn {
      font-weight: 400;
      font-size: 20px;
      line-height: 160%;
      color: var(--dark-color);

      &--active {
        outline: none;
        color: var(--accent-color);
        background-color: transparent;
      }

      &:focus {
        color: var(--accent-color);
        background-color: transparent;
      }
    }

    @include mobile {
      flex-wrap: wrap;
    }
  }
}
