.nav {
    &__list {
        @include flex-v-center;

        @include high-tablet {
          @include flex-all-center;

          flex-direction: column;

          height: 100%;
          width: 100%;
        }
    }

    &__item {
        &:not(:last-child) {
            margin-right: 40px;

            @include high-tablet {
              margin-right: 0;
              margin-bottom: 30px;
            }
        }
    }

    &__link {
        font-weight: 400;
        font-size: 20px;
        line-height: 160%;
        color: var(--light-color);

        @include high-tablet {
          font-weight: 600;
          font-size: 30px;
        }
    }

    @include high-tablet {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 999;
      overflow-y: auto;
      width: 100%;
      height: 100vh;
      background-color: var(--dark-color);
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
    }
}

.menu--active {
  opacity: 1;
  visibility: visible;
}
