.price {
  padding-top: 0;

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 40px;


    @include high-tablet {
      gap: 20px;
    }

    @include small-tablet {
      grid-template-columns: repeat(8, 1fr);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column: 4 span;
    border: 1px solid rgba(20, 16, 36, .2);
    border-radius: 20px;
    padding: 40px;
    transition: border-color .3s ease-in-out, background-color .3s ease-in-out, transform .3s ease-in-out;

    @include hover {
      border-color: var(--dark-color);
      background-color: var(--dark-color);
      transform: scale(1.05);

      .price__subtitle {
        color: var(--light-color);
      }

      .price__count {
        color: var(--light-color);
      }

      .price__descr {
        color: var(--light-color);
      }
    }

    @include small-tablet {
      grid-column: 8 span;
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 60px;
    font-weight: 600;
    font-size: 30px;
    line-height: 160%;
    text-align: center;
    color: var(--dark-color);
    transition: color .3s ease-in-out;

    &::after {
      content: '';
      display: block;
      margin-left: 10px;
      width: 20px;
      height: 3px;
      background-color: currentColor;
    }

    &::before {
      content: '';
      display: block;
      margin-right: 10px;
      width: 20px;
      height: 3px;
      background-color: currentColor;
    }
  }

  &__count {
    margin: 0;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 50px;
    line-height: 160%;
    text-align: center;
    color: var(--dark-color);
    transition: color .3s ease-in-out;

    @include high-desktop {
      font-size: 45px;
    }

    @include high-tablet {
      font-size: 32px;
    }
  }

  &__descr {
    margin: 0;
    margin-bottom: auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: var(--dark-color);
    transition: color .3s ease-in-out;
  }

  &__btn {
    margin-top: 60px;
    min-width: 100%;

    @include high-tablet {
      padding: 10px 20px;
      font-size: 16px;
    }
  }
}
