.form {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__label {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .just-validate-error-label {
    margin-bottom: 3px;
    color: var(--light-color)!important;
  }

  &__input {
    border-radius: 5px;
    width: 100%;
    height: 64px;
    padding: 0 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: var(--dark-color);

    &::placeholder {
      color: var(--text-color);
    }
  }


  &__btn {
    padding: 18px 30px;
  }
}
