.benefits {
  background-color: #f9f9f9;

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 40px;

    @include mobile {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__item {
    grid-column: 4 span;

    @include high-desktop {
      grid-column: 6 span;
    }

    @include mobile {
      grid-column: 4 span;
    }
  }

  &__subtitle {
    display: inline-flex;
    align-items: center;
    margin: 0;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 160%;
    color: var(--dark-color);
  }

  &__descr {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: var(--text-color);
  }
}
