.hero {
    padding-top: 160px;
    min-height: 800px;
    background-color: var(--dark-color);

    &__container {
        @include flex-v-center;

        @include high-tablet {
          display: block;
        }
    }

    &__content {
        max-width: 516px;

        @include high-tablet {
          @include flex-all-center;

          flex-direction: column;

          max-width: none;
          text-align: center;
        }
    }

    &__image {
      transform: translateX(167px);

      @include high-tablet {
        display: none;
      }
    }

    &__title {
        margin: 0;
        margin-bottom: 50px;
        font-weight: 600;
        font-size: 70px;
        line-height: 160%;
        color: var(--light-color);
        text-transform: uppercase;

        &-text {
            text-transform: initial;
            max-width: 90%;
            display: block;
            font-weight: 400;
            font-size: 20px;
            line-height: 160%;
            color: var(--light-color);

            @include high-tablet {
              max-width: none;
            }
        }

        @include mobile {
          font-size: 64px;
          line-height: 72px;
        }
    }

    @include high-tablet {
      min-height: auto;
      padding-bottom: 100px;
    }
}
