:root {
  --font-family: "Jost", sans-serif;
  --content-width: 1160px;
  --content-fluid-width: 1790px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(var(--content-fluid-width) + (var(--container-offset) * 2));
  --light-color: #fff;
  --accent-color: #d52027;
  --dark-color: #141024;
  --text-color: rgba(20, 16, 36, .7);
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
@font-face {
  font-family: "Jost";
  src: url("../fonts/../fonts/JostRegular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Jost";
  src: url("../fonts/../fonts/JostSemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Jost";
  src: url("../fonts/../fonts/JostBold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.container-fluid {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-fluid-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.section-offset {
  padding: 120px 0;
}
@media (max-width: 576px) {
  .section-offset {
    padding: 80px 0;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 25px 30px;
  min-width: 260px;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: var(--light-color);
  background-color: var(--accent-color);
}
.btn--dark {
  background-color: var(--dark-color);
}

.section-title {
  margin: 0;
  margin-bottom: 60px;
  font-weight: 600;
  font-size: 50px;
  line-height: 160%;
  color: var(--dark-color);
}
@media (max-width: 768px) {
  .section-title {
    line-height: 60px;
  }
}
@media (max-width: 576px) {
  .section-title {
    font-size: 32px;
    line-height: 42px;
  }
}

.tooltip {
  margin-left: 10px;
}
.tooltip__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  background: rgba(20, 16, 36, 0.08);
}
.tooltip__btn svg {
  width: 4px;
  height: 14px;
}
.tooltip__btn:focus + .tooltip__txt {
  opacity: 1;
  visibility: visible;
}
@media (any-hover: hover) {
  .tooltip__btn:hover + .tooltip__txt {
    opacity: 1;
    visibility: visible;
  }
}
.tooltip__txt {
  border-radius: 5px;
  padding: 15px 20px;
  box-shadow: 0 2px 8px rgba(20, 16, 36, 0.1);
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: var(--dark-color);
  background-color: var(--light-color);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .tooltip__txt:hover {
    opacity: 1;
    visibility: visible;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form__label {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}
.form__label:not(:last-child) {
  margin-bottom: 10px;
}
.form .just-validate-error-label {
  margin-bottom: 3px;
  color: var(--light-color) !important;
}
.form__input {
  border-radius: 5px;
  width: 100%;
  height: 64px;
  padding: 0 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: var(--dark-color);
}
.form__input::placeholder {
  color: var(--text-color);
}
.form__btn {
  padding: 18px 30px;
}

.burger {
  display: none;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  width: 44px;
  height: 44px;
}
.burger__line {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100px;
  width: 26px;
  height: 2px;
  background-color: var(--light-color);
  transform: translateX(-50%) translateY(-50%);
}
.burger__line:nth-child(1) {
  margin-top: -7px;
}
.burger__line:last-child {
  left: 9px;
  margin-top: 7px;
  width: 13px;
  transform: translateY(-50%);
}
@media (max-width: 992px) {
  .burger {
    display: block;
  }
}

.burger-close {
  display: none;
  position: absolute;
  right: var(--container-offset);
  top: 30px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  width: 44px;
  height: 44px;
  background-image: url("../img/close.svg");
  background-size: 14px 12px;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 992px) {
  .burger-close {
    display: block;
  }
}

@media (max-width: 576px) {
  .logo {
    width: 136px;
    height: 13px;
  }
}

.phone {
  font-weight: 600;
  font-size: 30px;
  line-height: 160%;
  color: var(--light-color);
}

.header {
  position: absolute;
  left: 0;
  top: 0;
  padding: 30px 0;
  width: 100%;
}
.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .header__container {
    justify-content: initial;
    align-items: center;
  }
}
@media (max-width: 992px) {
  .header__phone {
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    margin-left: auto;
    margin-right: 10px;
    width: 44px;
    height: 44px;
    font-size: 0;
    background-image: url("../img/phone.svg");
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.hero {
  padding-top: 160px;
  min-height: 800px;
  background-color: var(--dark-color);
}
.hero__container {
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  .hero__container {
    display: block;
  }
}
.hero__content {
  max-width: 516px;
}
@media (max-width: 992px) {
  .hero__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: none;
    text-align: center;
  }
}
.hero__image {
  transform: translateX(167px);
}
@media (max-width: 992px) {
  .hero__image {
    display: none;
  }
}
.hero__title {
  margin: 0;
  margin-bottom: 50px;
  font-weight: 600;
  font-size: 70px;
  line-height: 160%;
  color: var(--light-color);
  text-transform: uppercase;
}
.hero__title-text {
  text-transform: initial;
  max-width: 90%;
  display: block;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: var(--light-color);
}
@media (max-width: 992px) {
  .hero__title-text {
    max-width: none;
  }
}
@media (max-width: 576px) {
  .hero__title {
    font-size: 64px;
    line-height: 72px;
  }
}
@media (max-width: 992px) {
  .hero {
    min-height: auto;
    padding-bottom: 100px;
  }
}

.nav__list {
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  .nav__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
}
.nav__item:not(:last-child) {
  margin-right: 40px;
}
@media (max-width: 992px) {
  .nav__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.nav__link {
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: var(--light-color);
}
@media (max-width: 992px) {
  .nav__link {
    font-weight: 600;
    font-size: 30px;
  }
}
@media (max-width: 992px) {
  .nav {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    background-color: var(--dark-color);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
}

.menu--active {
  opacity: 1;
  visibility: visible;
}

.about {
  background-color: var(--light-color);
}
.about__descr {
  margin: 0;
  margin-bottom: 60px;
  max-width: 892px;
  font-weight: 400;
  font-size: 30px;
  line-height: 160%;
  color: var(--dark-color);
}
.about__descr-highlight {
  font-weight: 600;
}
.about__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 40px;
}
@media (max-width: 576px) {
  .about__list {
    grid-template-columns: repeat(4, 1fr);
  }
}
.about__item {
  grid-column: 3 span;
}
@media (max-width: 1200px) {
  .about__item {
    grid-column: 6 span;
  }
}
@media (max-width: 576px) {
  .about__item {
    grid-column: 4 span;
  }
}
.about__digit {
  font-weight: 600;
  font-size: 70px;
  line-height: 160%;
  color: var(--accent-color);
}
.about__text {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: var(--text-color);
}

.benefits {
  background-color: #f9f9f9;
}
.benefits__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 40px;
}
@media (max-width: 576px) {
  .benefits__list {
    grid-template-columns: repeat(4, 1fr);
  }
}
.benefits__item {
  grid-column: 4 span;
}
@media (max-width: 1200px) {
  .benefits__item {
    grid-column: 6 span;
  }
}
@media (max-width: 576px) {
  .benefits__item {
    grid-column: 4 span;
  }
}
.benefits__subtitle {
  display: inline-flex;
  align-items: center;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
  color: var(--dark-color);
}
.benefits__descr {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: var(--text-color);
}

.trainers__wrapper {
  padding-left: max(15px, (100% - var(--content-width)) / 2);
}
.trainers__content {
  margin-bottom: 75px;
}
.trainers .swiper-slide {
  width: 360px;
}
@media (max-width: 576px) {
  .trainers .swiper-slide {
    width: 220px;
  }
}
.trainers .swiper-slide::last-child {
  margin-right: var(--container-offset);
}
.trainers .swiper-scrollbar-drag {
  background-color: var(--dark-color);
}
.trainers__name {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
  color: var(--dark-color);
}
.trainers__post {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: var(--text-color);
}
.trainers__btn {
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: var(--accent-color);
}
.trainers__image {
  border-radius: 20px;
  margin-bottom: 20px;
  max-height: 500px;
}
@media (max-width: 576px) {
  .trainers__image {
    max-height: 306px;
  }
}
.trainers__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trainers__scroll {
  border-radius: 40px;
  width: 360px;
  height: 5px;
  background: rgba(20, 16, 36, 0.08);
}
.trainers__btns {
  display: flex;
  align-items: center;
}
@media (max-width: 576px) {
  .trainers__btns {
    display: none;
  }
}
.trainers__slider-btn {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
}
.trainers__slider-btn--prev {
  background-image: url("../img/arrow-left.svg");
}
.trainers__slider-btn--next {
  background-image: url("../img/arrow-right.svg");
}
.trainers__slider-btn:not(:last-child) {
  margin-right: 10px;
}

.price {
  padding-top: 0;
}
.price__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 40px;
}
@media (max-width: 992px) {
  .price__list {
    gap: 20px;
  }
}
@media (max-width: 768px) {
  .price__list {
    grid-template-columns: repeat(8, 1fr);
  }
}
.price__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 4 span;
  border: 1px solid rgba(20, 16, 36, 0.2);
  border-radius: 20px;
  padding: 40px;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .price__item:hover {
    border-color: var(--dark-color);
    background-color: var(--dark-color);
    transform: scale(1.05);
  }
  .price__item:hover .price__subtitle {
    color: var(--light-color);
  }
  .price__item:hover .price__count {
    color: var(--light-color);
  }
  .price__item:hover .price__descr {
    color: var(--light-color);
  }
}
@media (max-width: 768px) {
  .price__item {
    grid-column: 8 span;
  }
}
.price__subtitle {
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 60px;
  font-weight: 600;
  font-size: 30px;
  line-height: 160%;
  text-align: center;
  color: var(--dark-color);
  transition: color 0.3s ease-in-out;
}
.price__subtitle::after {
  content: "";
  display: block;
  margin-left: 10px;
  width: 20px;
  height: 3px;
  background-color: currentColor;
}
.price__subtitle::before {
  content: "";
  display: block;
  margin-right: 10px;
  width: 20px;
  height: 3px;
  background-color: currentColor;
}
.price__count {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 50px;
  line-height: 160%;
  text-align: center;
  color: var(--dark-color);
  transition: color 0.3s ease-in-out;
}
@media (max-width: 1200px) {
  .price__count {
    font-size: 45px;
  }
}
@media (max-width: 992px) {
  .price__count {
    font-size: 32px;
  }
}
.price__descr {
  margin: 0;
  margin-bottom: auto;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: var(--dark-color);
  transition: color 0.3s ease-in-out;
}
.price__btn {
  margin-top: 60px;
  min-width: 100%;
}
@media (max-width: 992px) {
  .price__btn {
    padding: 10px 20px;
    font-size: 16px;
  }
}

.order {
  background-image: url("../img/percents.png");
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  background-image: image-set("../img/percents.avif" type("image/avif"), "../img/percents.webp" type("image/webp"), "../img/percents.jpg" type("image/jpg"));
  padding: 87px 0;
  background-color: var(--accent-color);
  background-position: bottom center;
  background-size: cover;
}
.order__container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.order__left {
  grid-column: 5 span;
  padding-left: 60px;
}
@media (max-width: 992px) {
  .order__left {
    grid-column: 12 span;
    margin-bottom: 30px;
    padding-left: 0;
  }
}
.order__right {
  display: flex;
  justify-content: flex-end;
  grid-column: 7 span;
  padding-right: 60px;
}
@media (max-width: 992px) {
  .order__right {
    grid-column: 12 span;
    padding-right: 0;
    justify-content: flex-start;
  }
}
.order__form {
  max-width: 500px;
}
.order__title {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 36px;
  line-height: 130%;
  color: var(--light-color);
}
.order__descr {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: var(--light-color);
}

.footer {
  background-color: var(--dark-color);
  padding: 40px 0;
}
.footer__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 576px) {
  .footer__container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.footer__logo {
  display: inline-block;
  margin-bottom: 5px;
}
.footer__copyright {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: rgba(255, 255, 255, 0.7);
}
@media (max-width: 576px) {
  .footer__copyright {
    margin-bottom: 30px;
    text-align: center;
  }
}

.graph-modal__close {
  right: 40px;
  top: 40px;
  width: auto;
  height: auto;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: var(--text-color);
}
@media (max-width: 576px) {
  .graph-modal__close {
    top: 20px;
    right: 20px;
  }
}

@media (max-width: 1024px) {
  .graph-modal::before {
    display: none;
  }
}

.popup {
  max-width: 940px;
  width: 100%;
  padding: 40px;
}
.popup__top {
  display: flex;
  margin-bottom: 40px;
}
@media (max-width: 576px) {
  .popup__top {
    flex-direction: column;
  }
}
.popup__bottom-content {
  padding-top: 20px;
  padding-right: 20px;
  max-height: 375px;
  overflow-y: auto;
}
.popup__image {
  margin-right: 40px;
}
.popup__name {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
  color: var(--dark-color);
}
.popup__post {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: var(--text-color);
}
.popup__descr {
  margin-bottom: 40px;
}
.popup__text {
  margin: 0;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: var(--dark-color);
}
.popup__subtitle {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
  color: var(--dark-color);
}
.popup .simplebar-track.simplebar-vertical {
  border-radius: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 5px;
  background-color: rgba(20, 16, 36, 0.08);
}
.popup .simplebar-scrollbar:before {
  left: 0;
  right: 0;
  border-radius: 40px;
  width: 5px;
  background-color: var(--dark-color);
  opacity: 1;
}
@media (max-width: 768px) {
  .popup {
    padding-top: 80px;
  }
}
@media (max-width: 576px) {
  .popup {
    padding: 20px;
    padding-top: 40px;
  }
}

.tabs__menu {
  border-bottom: 1px solid rgba(20, 16, 36, 0.11);
  padding-bottom: 20px;
}
.tabs__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 70%;
}
@media (max-width: 576px) {
  .tabs__nav-item {
    margin-right: 40px;
    margin-bottom: 20px;
  }
}
.tabs__nav-btn {
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: var(--dark-color);
}
.tabs__nav-btn--active {
  outline: none;
  color: var(--accent-color);
  background-color: transparent;
}
.tabs__nav-btn:focus {
  color: var(--accent-color);
  background-color: transparent;
}
@media (max-width: 576px) {
  .tabs__nav {
    flex-wrap: wrap;
  }
}

.social {
  display: flex;
  align-items: center;
}
.social__item:not(:last-child) {
  margin-right: 10px;
}
.social__link {
  display: block;
  width: 30px;
  height: 30px;
}
.social__link svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: var(--dark-color);
  object-fit: cover;
}